@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

$base-color: #171c30;
$input-color: #171c30;
$content-color: #252c48;
$foreground-color: #3a4571;
/*
    11151C
    212D40
    364156
    7D4E57
    D66853
*/
* {
    box-sizing: border-box;
}
html,
body {
    color: white;
    background-color: $base-color;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vh;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#login {
    margin: 3rem;
}

.content {
    @include border-radius(2rem);
    background-color: $content-color;
}

#left-content {
    margin: 0;
    position: absolute;
    top: 3rem;
    left: 3rem;
    bottom: 3rem;
    width: 15%;
}

#right-content {
    position: absolute;
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 20%;
}

#right-content * {
    position: relative;
}

#background {
    @include border-radius(2rem);
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-image: url("./client/assets/candy.png");
    transition: opacity 0.5s linear;
}

#background.all-same {
    opacity: 1;
}

#title {
    color: #d66853;
}

.highest {
    font-weight: bold;
}

.lowest {
    font-weight: bold;
}

input[type="text"],
input[type="submit"] {
    background-color: $input-color;
    color: white;
    border: 0;
    padding: 1.5rem 3rem;
    font-size: 3rem;
    margin: 1rem;
    @include border-radius(1rem);
}

#story {
    font-size: 3rem;
    color: #ffffff;
    text-align: center;
    background-color: #7d4e57;
    font-style: italic;
    margin: 3rem;
    padding: 2rem 5rem;
    @include border-radius(1rem);
}

#results {
    text-align: center;
}

#cards {
    position: relative;
    width: 100%;
    text-align: center;
}

.card,
.result {
    @include border-radius(1rem);
    background-color: $foreground-color;
    color: white;
    display: inline-block;
    padding: 4rem 0px;
    width: 13rem;
    text-align: center;
    margin: 1rem;
    font-size: 5rem;
    border: .5rem solid $foreground-color;
}

.card.previous-answer {
    border: .5rem solid #7d4e57;
}

.card.picked {
    background-color: #7d4e57;
    border: .5rem solid #7d4e57;
}

.result.lowest,
.result.highest {
    background-color: #7d4e57;
    border: .5rem solid #7d4e57;
}

.delta {
    position: relative;
    font-size: 3rem;
    top: -1rem;
}

.name {
    font-size: 1.5rem;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

#player-states {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 2rem;
    text-align: left;
}
#player-states ul {
    border-top: 2px solid black;
    width: 100%;
    left: 0;
    padding: 0;
    margin-top: 5rem;
}
#player-states li {
    width: 100%;
    padding: 2rem;
    left: 0;
    line-height: 3rem;
    display: block;
    border-bottom: 2px solid black;
    text-overflow: ellipsis;
    overflow: hidden;
}

#player-states li.ready {
}
